import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";

import { theme } from "../StyledComponents";
import ToCoreID from "../ToCoreID";
import ToSmartID from "../ToSmartID";

import smartumLogo from "../../assets/smartum-logo.svg";

const AppHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SmartumLogo = styled.img`
  width: 89.6px;
  height: 14px;
  object-fit: contain;
  margin-left: 80px;
  margin-top: 41px;
`;

const AppContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AppTitle = styled.span`
  width: 400px;
  height: 42px;
  font-family: "Work Sans";
  font-size: 31px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.color.almostblack};
  margin-top: 2rem;
`;

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <AppHeader>
            <SmartumLogo src={smartumLogo} />
          </AppHeader>
          <AppContent>
            <AppTitle>{"SmartID Converter Tool"}</AppTitle>
            <ToCoreID />
            <ToSmartID />
          </AppContent>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
