import HashIds from "hashids";

let salt = process.env.REACT_APP_SALT || "rfkrOzE6sZUGOhjoqNjwaA";
if (window.location.host.split(".").includes("test")) {
  salt = "SMARTUM";
}

const padding = 16;
const alphabet =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";

const hashids = new HashIds(salt, padding, alphabet);

export default hashids;
