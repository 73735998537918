type PREFIX_TYPE = {
  value: string;
  name: string;
}

const PREFIXES: PREFIX_TYPE[] = [
  {
    value: "acct",
    name: "Account"
  },
  {
    value: "bnfc",
    name: "Beneficiary"
  },
  {
    value: "bpkg",
    name: "Benefit Package"
  },
  {
    value: "bpkgi",
    name: "Benefit Package Item"
  },
  {
    value: "cust",
    name: "Customer"
  },
  {
    value: "ptd",
    name: "Paytrail Deposit"
  },
  {
    value: "ptdi",
    name: "Paytrail Deposit Intent"
  },
  {
    value: "ptr",
    name: "Paytrail Receipt"
  },
  {
    value: "ppd",
    name: "Prepayment Deposit"
  },
  {
    value: "ppr",
    name: "Prepayment Request"
  },
  {
    value: "inv",
    name: "Invite"
  },
  {
    value: "pay",
    name: "Payment"
  },
  {
    value: "rfnd",
    name: "Refund"
  },
  {
    value: "rsrv",
    name: "Reservation"
  },
  {
    value: "role",
    name: "Role"
  },
  {
    value: "ssn",
    name: "SSN"
  },
  {
    value: "tx",
    name: "Transaction"
  },
  {
    value: "tr",
    name: "Transfer"
  },
  {
    value: "uben",
    name: "Used Benefit"
  },
  {
    value: "user",
    name: "User"
  },
  {
    value: "ven",
    name: "Venue"
  },
  {
    value: "dim",
    name: "Dimension"
  },
  {
    value: "dimv",
    name: "Dimension Value"
  },
  {
    value: "ord",
    name: "Order"
  },
  {
    value: "empl",
    name: "Employee"
  },
  {
    value: "ben",
    name: "Benefit"
  },
  {
    value: "emp",
    name: "Company"
  },
  {
    value: "sys",
    name: "System"
  },
  {
    value: "prtn",
    name: "Service Provider"
  },
  {
    value: "vcnt",
    name: "Venue Contract"
  }
];

export default PREFIXES.sort((a, b) => a.value[0].localeCompare(b.value[0]))
