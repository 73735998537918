import styled from "styled-components";

export const theme = {
  color: {
    white: "#ffffff",
    grey: "#9d9e9e",
    palegrey: "#ededed",
    orangered: "#fa5a32",
    red: "#e60a05",
    almostblack: "#14191a",
    lightgrey: "#c7c7c7"
  }
};

export const Container = styled.div`
  padding-left: 15rem;
  margin-top: 4rem;
`;

export const Title = styled.span`
  display: block;
  width: 380px;
  height: 24px;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.color.almostblack};
`;

export const Form = styled.div`
  display: flex;
`;

export const Input = styled.input`
  padding-left: 10px;
  width: ${props => props.width};
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c7;
  border-radius: 4px;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${props => props.theme.color.almostblack};
  margin-right: 8px;
  &:focus {
    outline: 0;
  }
`;

export const ConvertButton = styled.button`
  text-align: center;
  width: 110px;
  height: 45px;
  background-color: ${props => props.theme.color.orangered};
  border-radius: 5px;
  cursor: pointer;
  border: none;
  &:focus {
    outline: 0;
  }
  &:disabled {
    background-color: ${props => props.theme.color.palegrey};
    cursor: not-allowed;
    border: none;
  }
`;

export const ButtonText = styled.span`
  width: 62px;
  height: 24px;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.color.white};
`;

export const Result = styled.div`
  width: 253px;
  height: 48px;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.color.almostblack};
  margin-left: 1rem;
  &.span {
    display: block;
  }
`;

export const ExampleText = styled.span`
  width: 356px;
  height: 20px;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.color.lightgrey};
`;

export const Line = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  margin-right: 8px;
`;
