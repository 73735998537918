import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { isEmpty } from "lodash";

import infoImage from "../../assets/line.svg";

import {
  theme,
  Container,
  Title,
  Form,
  Input,
  ConvertButton,
  ButtonText,
  Result,
  Line,
  ExampleText,
} from "../StyledComponents";
import hashids from "../../helpers/hashids";
import ID_TYPES from "../../constants/ID_TYPES";

interface IConvertedValue {
  version: number;
  typeName: string;
  coreID: string;
}

const IDTestRegex = /^[a-zA-Z]{1,4}_[\wd]{16}$/;

const ToCoreID = () => {
  const [inputValue, setInputValue] = useState("");
  const [convertedValue, setConvertedValue] = useState<IConvertedValue>({
    version: 0,
    typeName: "",
    coreID: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  function onInputChange(e: React.FormEvent) {
    setInputValue((e.target as HTMLInputElement).value);
  }

  function convert() {
    if (!IDTestRegex.test(inputValue)) {
      setErrorMessage(
        "You enter the wrong format of the SmartID! Please check again!"
      );
      return false;
    }
    const [smartType, smartID] = inputValue.split("_");
    const coreType = ID_TYPES.find((type) => type.value === smartType);
    const [version, coreID] = hashids.decode(smartID);
    if (
      coreType &&
      !isNaN(Number(coreID)) &&
      (version === 1 || version === 2)
    ) {
      setErrorMessage("");
      setConvertedValue({
        typeName: coreType.name,
        coreID: `${coreID}`,
        version,
      });
    } else {
      setErrorMessage("Incorrect SmartID! Please check again!");
      setConvertedValue({ typeName: "", coreID: "", version: 0 });
    }
  }
  function renderCoreID() {
    const { typeName, coreID, version } = convertedValue;
    if (errorMessage) {
      return <span>{errorMessage}</span>;
    }

    return (
      <>
        {typeName ? (
          <span>
            {"Type:"} {typeName}
          </span>
        ) : null}
        <br />
        {version ? (
          <span>
            {"Version:"} {version === 1 ? "Core" : "Plus"}
          </span>
        ) : null}
        <br />
        {coreID ? (
          <span>
            {"CoreID:"} {coreID}
          </span>
        ) : null}
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>{"SmartID -> CoreID/PlusID"}</Title>
        <Form>
          <Input
            type="text"
            width="380px"
            value={inputValue}
            onChange={onInputChange}
            placeholder="SmartID"
          />
          <ConvertButton onClick={convert} disabled={isEmpty(inputValue)}>
            <ButtonText>{"Convert"}</ButtonText>
          </ConvertButton>

          <Result>{renderCoreID()}</Result>
        </Form>
        <Line src={infoImage} />
        <ExampleText className="example-text">
          {"e.g. tx_g9dfsa8FD3fgEQ1j"}
        </ExampleText>
      </Container>
    </ThemeProvider>
  );
};
export default ToCoreID;
